(function($) {
    $(function() {
        window.plugin = socius_referrals;

        window.app = {
            referrer: {},
            $logs: null,

            init: function() {
                var self = this;

                console.log('[Referrals]', '1.2');

                self.referrer = typeof referrer !== 'undefined' ? referrer : self.referrer;

                $('.popup-backdrop, .popup .btn-close, .popup .btn-cancel').on('click', function(e) {
                    e.preventDefault();
        
                    self.closePopup();
                });
        
                // Phone Masking
                $('.form-login [name="phone"]').mask('(000) 000-0000');
                $('.form-referral [name="phone"]').mask('(000) 000-0000');

                // Terms & Conditions
                $('.btn-terms').on('click', function(e) {
                    e.preventDefault();
        
                    $('.popup-backdrop').show();
                    $('.popup-terms').fadeIn();
                });

                // Register
                $('.btn-register').on('click', function(e) {
                    e.preventDefault();
        
                    var $popup = $('.popup-register');

                    $popup.find('[name="name"]').val('');
                    $popup.find('[name="phone"]').val('').mask('(000) 000-0000');
                    $popup.find('[name="email"]').val('');
                    $popup.find('[name="address"]').val('');
                    $popup.find('[name="city"]').val('');
                    $popup.find('[name="state"]').val('');
                    $popup.find('[name="zip"]').val('');

                    $('.popup-backdrop').show();
                    $popup.fadeIn();
                });

                $('.popup-register .btn-submit').on('click', self.register);
        
                // Edit Info
                $('.btn-edit').on('click', function(e) {
                    e.preventDefault();
        
                    let $popup =  $('.popup-edit');

                    $popup.find('[name="phone"]').val(referrer.phone).mask('(000) 000-0000');
                    $popup.find('[name="email"]').val(referrer.email);
                    $popup.find('[name="address"]').val(referrer.address);
                    $popup.find('[name="city"]').val(referrer.city);
                    $popup.find('[name="state"]').val(referrer.state);
                    $popup.find('[name="zip"]').val(referrer.zip);
                    $popup.find('button.icon').hide();

                    $('.popup-backdrop').show();
                    $popup.fadeIn();
                });

                // Share Via Email
                $('.btn-email').on('click', function(e) {
                    e.preventDefault();

                    let $popup = $('.popup-email'),
                        $input = $popup.find('input[name="email"]');
                    
                    $input.val('');
                    $popup.find('button.icon').hide();

                    $('.popup-backdrop').show();
                    $popup.fadeIn();
                });
        
                // Send Email
                $('.btn-send').on('click', self.sendEmail);
        
                // Copy Shareable Link
                $('.btn-link').on('click', self.copyLink);

                // Submit Referral
                $('.btn-referral').on('click', self.submitReferral);

                // Update Info
                $('.popup-edit .btn-update').on('click', self.updateInfo);

                // Submit Referral Offer
                $('.form-offer .btn-offer').on('click', self.submitReferralOffer);

                // Remove error class on focus
                $('input, select, textarea').on('focus', function(e) {
                    $(this).closest('.form-error').removeClass('form-error');
                });
            },

            log: function(messages, type, opts) {
                let self = this,
                    $popup = $('.popup:visible'),
                    $logs = self.$logs ? self.$logs : $popup.find('.logs'),
                    defaults = {
                        className: type,
                        position: 'top center',
                        autoHideDelay: 3000,
                        showAnimation: 'fadeIn',
                        showDuration: 400,
                        hideAnimation: 'fadeOut',
                        hideDuration: 200
                    };

                opts = $.extend(defaults, opts);

                let $elem = null;
                
                if (opts.$elem) {
                    $elem = opts.$elem;
                    delete opts.$elem;
                }

                messages = typeof messages === 'string' ? [messages] : messages;

                if (!$.isArray(messages)) {
                    self.$logs = null;
                    return;
                }

                if (messages.length === 1 && $elem) {
                    $elem.notify(messages[0], opts);
                } else {
                    messages.forEach(function(message, i) {
                        let $elem = $('<div></div>');

                        $elem.attr('class', type);
                        $elem.html(message);

                        $logs.append($elem);
                    });

                    $logs.slideDown();

                    setTimeout(function() {
                        $logs.slideUp(function() {
                            $logs.html('');
                        });
                    }, 2000);
                }

                self.$logs = null;
            },

            error: function(message, opts) {
                app.log(message, 'error', opts);
            },

            success: function(message, opts) {
                app.log(message, 'success', opts);
            },

            sendEmail: function(e) {
                e.preventDefault();
    
                let error = null,
                    $btn = $(this),
                    $icon = $btn.find('.icon'),
                    $popup = $('.popup-email'),
                    $input = $popup.find('input[name="email"]'),
                    emails = $input.val().trim();
    
                // Skip if disabled
                if ($btn.is(':disabled')) {
                    return;
                }
    
                // Validate an email was entered
                if (!emails.length) {
                    error = 'Please enter an email';
                } else {
                    // Validate valid email addresses
                    emails = emails.split(/,\s*/);
    
                    //console.log('Emails', emails);
    
                    emails.forEach(function(email, i) {
                        if (!error && !cms.isValidEmail(email)) {
                            error = `"${email}" is not a valid email`;
                        }
                    });
                }
    
                // Process send email
                if (!error) {
                    $icon.show();
                    $btn.prop('disabled', true);

                    $.ajax({
                        url: plugin.urls.rest + 'referrer.email/',
                        method: 'post',
                        data: {
                            emails: emails
                        },
                        dataType: 'json',
                        success: function(res) {
                            $icon.hide();
                            $btn.prop('disabled', false);

                            if (res.errors && res.errors.length) {
                                app.error(res.errors[0]);
                            } else if (res.successes && res.successes.length) {
                                app.success(res.successes[0]);

                                setTimeout(function() {
                                    app.closePopup();
                                }, 2000);
                            } else {
                                app.error('Unable to send email at this time');
                            }
                        },
                        error: function() {
                            $icon.hide();
                            $btn.prop('disabled', false);
                            app.error('Unable to send email at this time');
                        }
                    });
                }
    
                if (error) {
                    app.error(error);
                }
            },

            copyLink: function(e) {
                e.preventDefault();
    
                // Process copy link logic
                let success = app.copyText(window.referrer.link),
                    message = success ? 'Shareable link successfully copied to clipboard' : 'Unable to copy shareable link at this time',
                    $popup = $('.popup-alert');
    
                $('.popup-backdrop').show();
                $popup.find('.message').html(message);
                $popup.fadeIn();
    
                setTimeout(function() {
                    app.closePopup();
                }, 2000);
            },

            submitReferral: function(e) {
                var self = app,
                    errors = [],
                    $btn = $(this),
                    $arrow = $btn.find('.text .fa'),
                    $icon = $btn.find('.icon'),
                    $form = $btn.closest('.form-referral'),
                    $elem = $form.find('input:first').parent(),
                    opts = {$elem: $elem},
                    name = $form.find('input[name="name"]').val().trim(),
                    email = $form.find('input[name="email"]').val().trim(),
                    phone = $form.find('input[name="phone"]').val().trim(),
                    zip = $form.find('input[name="zip"]').val().trim();

                self.$logs = $form.find('.logs');

                // Validate name
                if (!name) {
                    errors.push({
                        name: 'name',
                        error: 'Please enter a name'
                    });
                }

                // Validate email
                if (!email) {
                    errors.push({
                        name: 'email',
                        error: 'Please enter an email'
                    });
                } else if (!cms.isValidEmail(email)) {
                    errors.push({
                        name: 'email',
                        error: 'The email entered is invalid'
                    });
                }

                // Validate phone
                if (!phone) {
                    errors.push({
                        name: 'phone',
                        error: 'Please enter a phone'
                    });
                } else {
                    phone = phone.replace(/[^\d]/g, '');

                    if (phone.length != 10) {
                        errors.push({
                            name: 'phone',
                            error: 'Please enter a 10 digit phone'
                        });
                    }
                }

                // Validate zip
                if (!zip) {
                    errors.push({
                        name: 'zip',
                        error: 'Please enter a zip'
                    });
                } else {
                    zip = zip.replace(/[^\d]/g, '');

                    if (zip.length != 5) {
                        errors.push({
                            name: 'zip',
                            error: 'Please enter a 5 digit zip'
                        });
                    }
                }

                // Process submit referral
                if (!errors.length) {
                    $icon.show();
                    $arrow.hide();
                    $btn.prop('disabled', true);

                    $.ajax({
                        url: plugin.urls.rest + 'referral.add/',
                        method: 'post',
                        data: {
                            name: name,
                            email: email,
                            phone: phone,
                            zip: zip
                        },
                        dataType: 'json',
                        success: function(res) {
                            $icon.hide();
                            $arrow.show();
                            $btn.prop('disabled', false);

                            if (res.errors && res.errors.length) {
                                self.error(res.errors[0], opts);
                            } else if (res.successes && res.successes.length) {
                                $form.find('input').val('');

                                self.success('Referral successfully submitted', opts);

                                setTimeout(function() {
                                    app.closePopup();
                                }, 2000);
                            } else {
                                self.error('Unable to submit referral at this time', opts);
                            }
                        },
                        error: function() {
                            $icon.hide();
                            $arrow.show();
                            $btn.prop('disabled', false);
                            self.error('Unable to submit referral at this time', opts);
                        }
                    });
                }

                if (errors.length) {
                    errors.forEach(function(item, i) {
                        var $item = $form.find('[name="' + item.name + '"]');
                        
                        $item.parent().addClass('form-error');

                        opts.$elem = $item.parent();
                        opts.position = 'right middle';

                        self.error(item.error, opts);
                    });
                }
            },

            updateInfo: function(e) {
                e.preventDefault();

                let error = null,
                    $btn = $(this),
                    $icon = $btn.find('.icon'),
                    $popup = $('.popup-edit'),
                    phone = $popup.find('[name="phone"]').val().trim(),
                    email = $popup.find('[name="email"]').val().trim(),
                    address = $popup.find('[name="address"]').val().trim(),
                    city = $popup.find('[name="city"]').val().trim(),
                    state = $popup.find('[name="state"]').val().trim(),
                    zip = $popup.find('[name="zip"]').val().trim();
    
                // Skip if disabled
                if ($btn.is(':disabled')) {
                    return;
                }

                // Validate phone
                if (!phone.length) {
                    error = 'Please enter a phone';
                } else {
                    phone = phone.replace(/[^\d]/g, '');

                    if (phone.length !== 10) {
                        error = 'Please enter a 10 digit phone';
                    }
                }

                // Validate email
                if (!email.length) {
                    error = 'Please enter an email';
                } else if (!cms.isValidEmail(email)) {
                    error = `"${email}" is not a valid email`;
                }
    
                // Validate address
                if (!address.length) {
                    error = 'Please enter an address';
                }

                // Validate city
                if (!city.length) {
                    error = 'Please enter a city';
                }

                // Validate state
                if (!state.length) {
                    error = 'Please enter a state';
                }

                // Validate zip
                if (!zip.length) {
                    error = 'Please enter a zip';
                } else {
                    zip = zip.replace(/[^\d]/g, '');

                    if (zip.length !== 5) {
                        error = 'Please enter a 5 digit zip';
                    }
                }

                // Process update info
                if (!error) {
                    $icon.show();
                    $btn.prop('disabled', true);

                    $.ajax({
                        url: plugin.urls.rest + 'referrer.update/',
                        method: 'post',
                        data: {
                            phone: phone,
                            email: email,
                            address: address,
                            city: city,
                            state: state,
                            zip: zip
                        },
                        dataType: 'json',
                        success: function(res) {
                            $icon.hide();
                            $btn.prop('disabled', false);

                            if (res.errors && res.errors.length) {
                                app.error(res.errors[0]);
                            } else if (res.successes && res.successes.length) {
                                app.referrer.phone = phone;
                                app.referrer.zip = zip;
                                app.referrer.email = email;

                                app.success('Info successfully updated');

                                setTimeout(function() {
                                    app.closePopup();
                                }, 2000);
                            } else {
                                app.error('Unable to update info at this time');
                            }
                        },
                        error: function() {
                            $icon.hide();
                            $btn.prop('disabled', false);
                            app.error('Unable to update info at this time');
                        }
                    });
                }
    
                if (error) {
                    app.error(error);
                }
            },

            register: function(e) {
                e.preventDefault();

                let error = null,
                    $btn = $(this),
                    $icon = $btn.find('.icon'),
                    $popup = $('.popup-register'),
                    name = $popup.find('[name="name"]').val().trim(),
                    phone = $popup.find('[name="phone"]').val().trim(),
                    email = $popup.find('[name="email"]').val().trim(),
                    address = $popup.find('[name="address"]').val().trim(),
                    city = $popup.find('[name="city"]').val().trim(),
                    state = $popup.find('[name="state"]').val().trim(),
                    zip = $popup.find('[name="zip"]').val().trim();
    
                // Skip if disabled
                if ($btn.is(':disabled')) {
                    return;
                }

                // Validate name
                if (!name.length) {
                    error = 'Please enter a name';
                }

                // Validate phone
                if (!phone.length) {
                    error = 'Please enter a phone';
                } else {
                    phone = phone.replace(/[^\d]/g, '');

                    if (phone.length !== 10) {
                        error = 'Please enter a 10 digit phone';
                    }
                }

                // Validate email
                if (!email.length) {
                    error = 'Please enter an email';
                } else if (!cms.isValidEmail(email)) {
                    error = `"${email}" is not a valid email`;
                }

                // Validate address
                if (!address.length) {
                    error = 'Please enter an address';
                }

                // Validate city
                if (!city.length) {
                    error = 'Please enter a city';
                }

                // Validate state
                if (!state.length) {
                    error = 'Please enter a state';
                }

                // Validate zip
                if (!zip.length) {
                    error = 'Please enter a zip';
                } else {
                    zip = zip.replace(/[^\d]/g, '');

                    if (zip.length !== 5) {
                        error = 'Please enter a 5 digit zip';
                    }
                }
    
                // Process update info
                if (!error) {
                    $icon.show();
                    $btn.prop('disabled', true);

                    $.ajax({
                        url: plugin.urls.rest + 'referrer.register/',
                        method: 'post',
                        data: {
                            name: name,
                            phone: phone,
                            email: email,
                            address: address,
                            city: city,
                            state: state,
                            zip: zip
                        },
                        dataType: 'json',
                        success: function(res) {
                            $icon.hide();
                            $btn.prop('disabled', false);

                            if (res.errors && res.errors.length) {
                                app.error(res.errors[0]);
                            } else if (res.successes && res.successes.length) {
                                app.success('Your account was successfully created');

                                setTimeout(function() {
                                    app.closePopup();
                                }, 2000);
                            } else {
                                app.error('Unable to register at this time');
                            }
                        },
                        error: function() {
                            $icon.hide();
                            $btn.prop('disabled', false);
                            app.error('Unable to register at this time');
                        }
                    });
                }
    
                if (error) {
                    app.error(error);
                }
            },

            submitReferralOffer: function(e) {
                e.preventDefault();

                if (!window.referrer) {
                    alert('Unable to submit referral offer at this time');
                    return;
                }

                let self = app,
                    errors = [],
                    $btn = $(this),
                    $arrow = $btn.find('.text .fa'),
                    $icon = $btn.find('.icon'),
                    $form = $btn.closest('.form'),
                    $elem = $form.find('input:first').parent(),
                    opts = {$elem: $elem},
                    name = $form.find('[name="name"]').val().trim(),
                    email = $form.find('[name="email"]').val().trim(),
                    phone = $form.find('[name="phone"]').val().trim(),
                    zip = $form.find('[name="zip"]').val().trim(),
                    product = $form.find('[name="product"]').val().trim();

                // Skip if disabled
                if ($btn.is(':disabled')) {
                    return;
                }

                // Validate name
                if (!name) {
                    errors.push({
                        name: 'name',
                        error: 'Please enter a name'
                    });
                }

                // Validate email
                if (!email) {
                    errors.push({
                        name: 'email',
                        error: 'Please enter an email'
                    });
                } else if (!cms.isValidEmail(email)) {
                    errors.push({
                        name: 'email',
                        error: 'The email entered is invalid'
                    });
                }

                // Validate phone
                if (!phone) {
                    errors.push({
                        name: 'phone',
                        error: 'Please enter a phone'
                    });
                } else {
                    phone = phone.replace(/[^\d]/g, '');

                    if (phone.length != 10) {
                        errors.push({
                            name: 'phone',
                            error: 'Please enter a 10 digit phone'
                        });
                    }
                }

                // Validate zip
                if (!zip) {
                    errors.push({
                        name: 'zip',
                        error: 'Please enter a zip'
                    });
                } else {
                    zip = zip.replace(/[^\d]/g, '');

                    if (zip.length != 5) {
                        errors.push({
                            name: 'zip',
                            error: 'Please enter a 5 digit zip'
                        });
                    }
                }

                // Validate product
                if (!product) {
                    errors.push({
                        name: 'product',
                        error: 'Please enter a product'
                    });
                }

                // Process submit referral
                if (!errors.length) {
                    $icon.show();
                    $arrow.hide();
                    $btn.prop('disabled', true);

                    $.ajax({
                        url: plugin.urls.rest + 'referral.offer/',
                        method: 'post',
                        data: {
                            name: name,
                            email: email,
                            phone: phone,
                            zip: zip,
                            product: product,
                            hash: window.referrer.hash
                        },
                        dataType: 'json',
                        success: function(res) {
                            $icon.hide();
                            $arrow.show();
                            $btn.prop('disabled', false);

                            if (res.errors && res.errors.length) {
                                self.error(res.errors[0], opts);
                            } else if (res.successes && res.successes.length) {
                                $form.find('input, select').val('');

                                self.success(res.successes[0], opts);

                                setTimeout(function() {
                                    app.closePopup();
                                }, 2000);
                            } else {
                                self.error('Unable to submit at this time', opts);
                            }
                        },
                        error: function() {
                            $icon.hide();
                            $arrow.show();
                            $btn.prop('disabled', false);
                            self.error('Unable to submit at this time', opts);
                        }
                    });
                }

                if (errors.length) {
                    errors.forEach(function(item, i) {
                        var $item = $form.find('[name="' + item.name + '"]');
                        
                        $item.parent().addClass('form-error');

                        opts.$elem = $item.parent();
                        opts.position = 'right middle';

                        self.error(item.error, opts);
                    });
                }
            },

            copyText: function(value) {
                value = typeof value === 'string' ? value.trim() : '';

                // Validate value
                if (!value.length) {    
                    return false;
                }
    
                // Copy link
                var elem = document.createElement('input');
                
                elem.setAttribute('id', 'input-link');
                //elem.setAttribute('type', 'hidden');
    
                document.body.appendChild(elem);
    
                document.getElementById('input-link').value = value;
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);
    
                return true;
            },

            closePopup: function() {
                $('.popup').fadeOut(function() {
                    $('.popup-backdrop').hide();
                });
            }
        };

        app.init();
    });
})(jQuery);